import * as React from 'react'
import Svg, { SvgProps, Defs, Path, G, Mask, Use } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: filter */

interface Props {
  title?: string
  titleId?: string
  size?: number
}

// ;<svg
//   width="512px"
//   height="512px"
//   viewBox="0 0 512 512"
//   version="1.1"
//   xmlns="http://www.w3.org/2000/svg"
//   xmlns:xlink="http://www.w3.org/1999/xlink">
//   <defs>
//     <linearGradient
//       x1="100%"
//       y1="100%"
//       x2="-7.35626221%"
//       y2="-6.76879883%"
//       id="linearGradient-ndq71t6k-v-1">
//       <stop stop-color="#963CCF" offset="0%"></stop>
//       <stop stop-color="#8F5ED7" offset="20%"></stop>
//       <stop stop-color="#847ADF" offset="40%"></stop>
//       <stop stop-color="#7394E7" offset="60%"></stop>
//       <stop stop-color="#5AACEE" offset="80%"></stop>
//       <stop stop-color="#22C4F6" offset="100%"></stop>
//     </linearGradient>
//   </defs>
//   <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
//     <g id="Icon" fill="url(#linearGradient-ndq71t6k-v-1)">
//       <rect id="Background" x="0" y="0" width="512" height="512"></rect>
//     </g>
//   </g>
// </svg>

function SvgComponent({ title, titleId, ...props }: SvgProps & Props) {
  return (
    <Svg
      width="1em"
      height="1em"
      viewBox="0 0 255 314"
      aria-labelledby={titleId}
      accessibilityRole="image"
      focusable={false}
      style={[
        props.style,
        props.size
          ? {
              fontSize: props.size,
            }
          : undefined,
      ]}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <Defs>
        <Path
          d="M0 190v114.082a9 9 0 0016.674 4.701L89.447 190H0z"
          id="prefix__a"
        />
        <Path id="prefix__e" d="M97.154 216.924L-16.978 190h134.26z" />
      </Defs>
      <G fill="none" fillRule="evenodd">
        <Path
          d="M0 190V7a7 7 0 017-7h153c52.467 0 95 42.533 95 95s-42.533 95-95 95H0z"
          fill="#31C5F4"
        />
        <Mask id="prefix__c" fill="#fff">
          <Use xlinkHref="#prefix__a" />
        </Mask>
        <Use fill="#1483A8" xlinkHref="#prefix__a" />
        {/* <Use fill="#000" filter="url(#prefix__b)" xlinkHref="#prefix__a" /> */}
        <G mask="url(#prefix__c)">
          {/* <Use fill="#000" filter="url(#prefix__d)" xlinkHref="#prefix__e" /> */}
          <Use fill="#0F7190" xlinkHref="#prefix__e" />
          {/* <Use fill="#000" filter="url(#prefix__f)" xlinkHref="#prefix__e" /> */}
        </G>
      </G>
    </Svg>
  )
}

const LogoIcon = React.memo(SvgComponent)
export default LogoIcon
