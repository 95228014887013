import * as React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import Head from 'next/head'
import { Typemark } from '@components/Branding'
import { FallbackSeo } from '@components/SEO/FallbackSeo'
import * as Colors from '@themes/Colors'
import Fonts from '@themes/Fonts'

const NotFoundPage = () => {
  return (
    <View style={styles.container}>
      <FallbackSeo />
      <Head>
        <title key="title">404 Error - Page not found</title>
      </Head>
      <Text style={styles.text}>
        <Typemark />
      </Text>
      <View>
        <Text style={styles.preHeading}>404 Error</Text>
        <Text style={styles.heading}>Page not found</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    color: '#cdced4',
    fontSize: 24,
  },
  preHeading: {
    userSelect: 'none',
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: Fonts.type.sofia,
    color: Colors.black,
  },
  heading: {
    userSelect: 'none',
    fontSize: 36,
    fontWeight: 'bold',
    fontFamily: Fonts.type.sofia,
    color: Colors.black,
  },
})

export default NotFoundPage
